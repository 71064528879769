#incident-mgmt-page {
  width: 100%;
  height: 100%;
  padding: 1.5%;

  .incident-mgmt-left-container,
  .incident-mgmt-right-container {
    height: fit-content;
    max-height: 100%;
    overflow-y: scroll;
    padding: 1%;
    border-radius: 5px;
    background-color: white;
  }

  .incident-mgmt-left-container {
    width: 40%;

    .input-label {
      display: block;
      margin-bottom: 3px;
    }

    .input-label-flex {
      margin-bottom: 3px;
    }

    .no-issues {
      color: black;
    }
  }

  .incident-mgmt-right-container {
    width: 60%;

    .section-title {
      font-weight: 700;
    }

    #issue-preview {
      color: black !important;
    }

    #issue-list {
      color: black !important;
    }
  }
}
