.main-dashboard-container {
  height: 100%;
  .ant-carousel {
    width: 100%;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    color: var(--color-text);
  }
}

$main-dashboard-button-max-width: 300px;
$main-dashboard-button-margin: 10px;
$main-dashboard-six-items-per-page-width: (
  3 * $main-dashboard-button-max-width + 6 * $main-dashboard-button-margin
);

.main-dashboard-button {
  max-width: $main-dashboard-button-max-width;
  margin: $main-dashboard-button-margin;
  height: 280px;
  border-radius: 8px;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .card-label {
    display: flex;
    text-align: center;
    font-size: 20px;
  }

  &:hover {
    background-color: var(--color-card-background-hover);
    border: 1px solid $brand-blue;

    .card-label {
      color: var(--color-button-child) !important;
    }

    use {
      fill: var(--color-button-child);
    }
  }

  use {
    fill: darkgray;
  }

  @media screen and (max-height: 720px), (max-width: 1060px) {
    flex-direction: row;
    justify-content: left;
    height: 120px;

    .ant-card-meta-title {
      font-size: 15px;
    }
  }

  @media (max-height: 560px) {
    flex-direction: row;
    justify-content: left;
    height: 80px;

    .ant-card-meta-title {
      font-size: 15px;
    }
  }

  flex: 1 0 auto;
  box-sizing: border-box;
  width: 100%;
}

.main-dashboard-icon {
  flex: 0 0 100px;
  width: 100px;
  height: 100px;

  svg {
    width: 100%;
    height: 100%;
  }

  // same as @media in .main-dashboard-button (see there how 720px computes)
  @media screen and (max-height: 720px), (max-width: 1060px) {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
  }
}

.page-wrapper {
  display: flex !important;
  justify-content: center;

  .ant-col {
    height: inherit !important;
  }
}

.row-wrapper {
  width: 865px;
  max-width: 865px;
}

@media (min-width: 740px) and (max-width: 1060px) {
  .row-wrapper {
    width: 640px;
  }

  .card-label {
    text-align: start !important;
    font-size: 16px !important;
  }
}

@media (max-width: 740px) {
  .row-wrapper {
    width: 320px;
  }

  .card-label {
    text-align: start !important;
    font-size: 16px !important;
  }
}

.main-dashboard-button-wrapper {
  display: flex;
  justify-content: center;
}

.slick-dots {
  bottom: -50px !important;
}

.slick-prev::after,
.slick-next::after {
  scale: 1.5;
}

.slick-arrow {
  margin: 20px;
  margin-top: 0px;
}
