@import 'utility';

.component-fault-history-group {
  min-height: 80px;

  .cell {
    flex: 1;
  }

  .header-container {
    flex: 1;
    padding-left: 25px;
    min-height: 80px;
    cursor: pointer;

    .arrow {
      padding-right: 10px;
    }

    .fault-type-container {
      flex: 1;

      .fault-type-name {
        @extend .modal-prominent-text;
      }
    }

    .last-time-applied {
      flex: 1;
    }

    .annotation-count-container {
      flex: 1;

      .no-comments {
        color: $gray;
        font-size: 14px;
      }

      .annotation-count {
        padding-left: 10px;
      }
    }
  }

  .history-item {
    min-height: 80px;
    padding-left: 55px;

    .vin {
      font-weight: 600;
      width: 30%;
    }

    .title {
      font-size: 14px;
      color: $black;
    }

    .last-time-applied {
      font-size: 14px;
      width: 20%;
    }

    .annotation {
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 50%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
