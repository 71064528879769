.right-sidebar-content {
  overflow-y: scroll;
  pointer-events: all;
  height: 34px;

  .button-collapse {
    height: 34px;
    padding: 6px;
    border-radius: 7px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    border-bottom: 1px solid transparent;
    background-color: var(--color-collapsable-sidepanel-background-header);
    color: var(--color-collapsable-sidepanel-text);
    cursor: pointer;
    position: absolute;
    right: 0px;
  }

  .button-collapse:hover {
    color: var(--color-collapsable-sidepanel-text);
  }
}

.sidepanel-container {
  height: 100%;
  border-left: 1px solid var(--color-border-custom);
}

.right-sidebar {
  z-index: 1;
  width: 278px;

  height: 100%;
  overflow-y: scroll;
  pointer-events: all;

  .not-relevant-ctrl {
    border-bottom: 1px solid var(--color-border-custom);
    width: 100%;
    text-align: left;
    padding: 5px 8px;
  }
}

.left-panel-container {
  .back-button-container {
    padding: 5px;
    padding-left: 8%;
    border-bottom: 1px solid var(--color-border-custom);

    * {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .left-panel-body-title {
    margin: 0 5px;
    padding: 10px;
    padding-left: 15px;
    border-radius: 5px;
    background-color: var(--color-background-vehicle-test-side-panel-header);
    color: var(--color-text-vehicle-test-side-panel-header);
  }

  .left-panel-body {
    padding: 5px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
}
