#reports-page {
  height: 100%;
  width: 100%;

  .testzone {
    padding-top: 5px;
  }

  .reports-breadcrumb-wrapper {
    background-color: var(--color-text-vehicle-test-side-panel-header);
    padding: 6px;
    padding-left: 10px;
    border-bottom: 1px solid var(--color-border-custom);
    width: 100%;
    height: 4%;
  }

  .reports-page-body {
    height: 96%;
    width: 100%;
  }

  .reports-page-body-right,
  .reports-page-body-left {
    padding: 15px;
  }

  .reports-page-body-right {
    border-left: 1px solid var(--color-border-custom);
  }

  .reports-page-body-left {
    overflow-y: scroll;
  }

  #failures-overview {
    font-weight: normal;
    margin-top: 20px;
    border: 1px solid var(--color-border-custom);
    overflow: hidden;
    border-radius: 5px;
    .failures-day:not(:last-child),
    .failure-hour:not(:last-child) {
      border-bottom: 1px solid var(--color-border-custom);
    }
    .failure-date {
      padding: 5px 20px;
      border-bottom: 1px solid var(--color-border-custom);
      background-color: var(--color-text-vehicle-test-side-panel-header);
    }
    .failure-hour {
      display: flex;
      padding: 5px 20px 0px;
      .time-check-box {
        min-width: 150px;
        padding-top: 5px;
      }
    }
    .failures-count {
      padding: 10px 0px;
      display: flex;
      justify-content: space-around;
      background-color: var(--color-text-vehicle-test-side-panel-header);
      border-bottom: 1px solid var(--color-border-custom);
      .count-type {
        border: 1px solid var(--color-border-custom);
        padding: 2px 5px;
        border-radius: 5px;
      }
    }
  }
  .fault-chart {
    width: 100%;
    height: 50px;
  }
  .harness-dot {
    height: 10px;
    width: 10px;
    background-color: #ffc000;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .component-dot {
    height: 10px;
    width: 10px;
    background-color: #0189e9;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .other-dot {
    height: 10px;
    width: 10px;
    background-color: #d74053;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .device-dot {
    height: 10px;
    width: 10px;
    background-color: #9ba52a;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .no-faults {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-top: 20px;
    border: 2px solid #4b566a;
    height: 80px;
  }
  #selected-periods-info {
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
  }

  .report-switch .switch-radio-option {
    align-items: center;
  }

  .report-switch {
    padding: 12px 0px;
  }

  .reports-table-container {
    height: 100%;
    .fault-table {
      height: 45%;

      .fault-report-legend {
        align-self: flex-end;
      }
    }
    .selected-fault-table {
      height: 55%;
    }
  }

  .report-vin-anchor {
    color: var(--color-text);
  }
}

.select-test-zone-modal {
  .checkbox label {
    padding-left: 0px !important;
  }
}
