.fuses-and-relays-container {
  align-items: flex-start;

  .power-distribution-box-image {
    height: 100%;
    width: 100%;
    flex: 2;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--color-border-custom);
  }

  .units {
    padding-left: 10px;
    flex: 1;

    .unit {
      margin-top: 10px;

      .unit-title {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
