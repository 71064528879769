.rect,
.circle {
  fill: var(--color-diagram-device-fill);
  transition: stroke 100ms ease-in-out;
  stroke: var(--color-diagram-port-stroke);
  shape-rendering: geometricPrecision;
  stroke-width: 1pt;

  &:not(.selectionDisabled):not(.disabled) {
    cursor: pointer;

    &:hover {
      stroke: var(--color-diagram-port-stroke-hover);
    }

    &.dragging {
      stroke: var(--color-diagram-port-stroke-hover);
    }

    &.active {
      fill: var(--color-diagram-port-fill-active);
      stroke: var(--color-diagram-port-stroke-active);
    }

    &.unlinkable {
      stroke: var(--color-diagram-device-stroke-unlinkable);
    }

    &.deleteHovered {
      stroke: var(--color-diagram-device-stroke-unlinkable) !important;
      stroke-dasharray: 4 2;
    }
  }

  &:focus {
    outline: none;
  }

  &.children {
    fill: transparent;
    stroke: var(--color-diagram-port-stroke);
  }
}

.circle {
  &:hover {
    stroke: var(--color-diagram-port-stroke-hover);
    fill: var(--color-diagram-port-stroke-hover);
  }
  &.active {
    fill: var(--color-diagram-port-stroke-active) !important;
  }
}

.node-label {
  fill: var(--color-diagram-port-label) !important;

  &.active {
    fill: var(--color-diagram-port-label-active);
  }
}
