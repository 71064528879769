.cant-fix-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.cant-fix-reason-icon {
  display: flex;
  margin-right: 7px;
  align-items: center;
}

#submit-cant-fix > * .radio {
  font-size: 16px;
  margin-bottom: 15px;
}
