.full-screen-modal {
  height: 100vh;
  top: 0;
  max-width: 100%;
  margin: 0;
  padding: 0;
  z-index: 3;
  .ant-modal-content {
    height: 100vh;
    padding: 0px;
  }

  .header-container {
    padding: 0px 20px;

    div {
      height: 100%;
    }
    border-bottom: 1px solid var(--color-border-custom);
  }

  .ant-layout {
    height: 100vh;
  }

  .content-container {
  }
}
