.additional-component-fault-menu-mb {
  margin-bottom: 10px;
}

.additional-component-fault-menu-ml {
  margin-left: 10px;
  margin-right: 5px;
}

.additional-fault-menu-option {
  word-wrap: break-word;
}
