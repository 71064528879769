.quiz {
  width: 90%;
  min-height: 300px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  justify-content: space-evenly;
  transform: translate(5%, -200%);
}

.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
}

.testButton {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #1890ff;
  justify-content: flex-start;
  align-items: center;
  border: 0px solid #234668;
  cursor: pointer;
  text-align: center;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

.button:hover {
  background-color: #555e7d;
}

.button:focus {
  outline: none;
}

.button svg {
  margin-right: 5px;
}

.getting-started-container {
  height: 100%;
  width: 100%;
  padding: 0 16.2%;
  .getting-started-top-section {
    height: 57%;

    .getting-started-top-section-child {
      width: 100%;
      height: 100%;
    }
  }
  .getting-started-bottom-section {
    height: 43%;

    .getting-started-quiz-table {
      height: 65%;
    }

    .final-test .ant-table-row {
      font-weight: bold;
    }
  }
}

.quiz-modal-header {
  height: 35px;
  padding: 5px;
}
