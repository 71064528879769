@import '../assets/styles/partials/variables';

.block-diagram,
.detail-diagram,
.location-image-container,
.component-image-container {
  width: 100%;
  height: 100%;
}

.component-image-container {
  background-color: var(--color-background-main);
}

.location-image-container {
  background-color: var(--color-background-alternate);
}

.tracer-image-viewer {
  position: relative;
}

.base-diagram-container {
  position: relative;
  background-color: var(--color-background-main);
}
