.user-list-container {
  height: 100%;
  width: 100%;
  padding: 40px;

  .edit-user-link,
  .edit-user-link:hover {
    color: #0189e9;
  }

  .add-user-button {
    text-decoration: none !important;
  }

  .search-box {
    width: 35%;
  }

  .user-list-table-container {
    width: 70%;
  }
}

#user-form-container {
  form {
    width: 35%;
    background-color: var(--color-form-container);
    border-radius: 5px;
    padding: 15px;

    .user-form-cancel {
      text-decoration: none !important;
    }
  }
}
