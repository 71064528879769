.loading-container {
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
  p {
    font-size: 3rem;
  }

  span {
    color: var(--color-text);
  }
}

@mixin loading-text {
  p {
    font-size: 30px;
    padding-bottom: 10px;
    text-align: center;
  }
}

.loading {
  @include loading-text();
  margin-top: 10%;
}
