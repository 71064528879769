.port {
  fill: var(--color-diagram-port-fill);
  stroke: var(--color-diagram-port-stroke);
  stroke-width: 1pt;
  shape-rendering: geometricPrecision;

  &:not(.disabled) {
    cursor: pointer;

    &.active {
      fill: var(--color-diagram-port-fill-active);
      stroke: var(--color-diagram-port-stroke-active);
      stroke-width: 1px;
    }

    &:hover {
      stroke: var(--color-diagram-port-stroke-hover);
    }
  }
}
.port-label {
  fill: var(--color-diagram-port-label);
  pointer-events: none;
  font-size: 9px;
  text-rendering: geometricPrecision;
  user-select: none;

  &.active {
    fill: var(--color-diagram-port-label-active);
  }
}
