#dtc-list {
  .collapsible-group-container {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
  }
  .collapsible-group-header {
    border-radius: 5px;
    padding-left: 5px;
    background-color: var(--color-background-dtc-header);
    border: 1px solid var(--color-border-custom);
    a {
      color: var(--color-text);
    }

    .collapse-icon {
      color: var(--color-text);
    }
  }

  .ant-card {
    .ant-card-body {
      padding: 0;
    }
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;

    .collapsible-list-item {
      padding: 5px;
      &.active {
        background-color: var(--color-dtc-active);
      }

      &.indicated {
        background-color: var(--color-dtc-active);
      }

      &.unsupported {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
      }
      .subtitle {
        white-space: normal;
      }

      &.misconfigured {
        background-color: rgba(19, 23, 33, 0.5);

        .icon-warning {
          cursor: pointer;
        }
      }

      &.disabled,
      &.disabled:hover {
        background-color: inherit;
        color: inherit;
        .dtc-title-container,
        .dtc-subtitle {
          opacity: 0.6;
        }
      }
    }
  }
}

.dtc-title-container {
  padding-bottom: 5px;
  .dtc-title {
    display: flex;
    font-weight: 700;
    width: 100%;
  }
}

.dtc-title > div:first-child {
  padding-right: 20px;
}

.component-list-item {
  border-radius: 5px;
  padding: 8px;
  padding-left: 10px;
  cursor: pointer;

  .ant-card-body {
    padding: 0;
  }

  .component-list-item-title {
    margin-bottom: 5px;
    svg {
      width: 15px;
      height: 15px;
    }
  }

  &.indicate {
    background-color: var(--color-component-indicate);

    &.verified {
      background-color: var(--color-component-success);
    }

    &.faulty {
      background-color: var(--color-component-danger);
    }
  }
}

.component-item-container-card {
  .ant-card-body {
    padding: 15px;
    padding-left: 10px;
  }

  .component-fault-verify-buttons-container {
    padding-top: 5%;
  }

  .component-item-container-card-header {
    margin-bottom: 5px;
    svg {
      width: 15px;
      height: 15px;
    }
  }

  &.verified {
    background-color: var(--color-component-success);
  }

  &.faulty {
    background-color: var(--color-component-danger);
  }
}

.collapsible-group-container {
  height: 100%;

  .collapsible-group-header {
    flex-basis: 3%;
    background-color: var(--color-collapsable-sidepanel-background-header);
    justify-content: space-between;
    padding: 6px 10px;

    a {
      color: var(--color-collapsable-sidepanel-text);
    }

    a:hover {
      color: var(--color-collapsable-sidepanel-text);
      text-decoration: none;
    }

    #show-hide-dtc-memo-edit {
      text-decoration: underline;
    }

    .collapse-icon {
      color: var(--color-collapsable-sidepanel-text);
    }
  }

  .collapsible-group-content {
    flex-basis: 97%;
    background-color: var(--color-background-collapsible-panel);
    overflow-y: scroll;
  }

  .collapsed {
    display: none;
  }

  .expanded {
    display: block;
  }
}

//embeddedmode
.list-group-item {
  margin-bottom: -$global-border-width;
  padding: 15px 10px;
  position: relative;

  @media (max-width: 900px) {
    padding: 10px 5px;

    &.active {
      padding: 10px 5px;
    }
  }
}

.component-history-popover {
  max-height: 400px;
  overflow-y: scroll;
}
