$counter-diameter: 25px;
.round-counter {
  width: $counter-diameter;
  height: $counter-diameter;
  border-radius: $counter-diameter / 2;
  background-color: $brand-orange;
  color: $white;
  position: absolute;
  font-size: $small-font-size;
  font-weight: $font-bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fault-type-container {
  height: 100%;
  background-color: transparent;
  border-right: 1px solid var(--color-border-custom);
}

.fault-type-component-details {
  padding: 20px;
  border-bottom: 1px solid var(--color-border-custom);
  flex-basis: 30%;
}

.incident-count {
  @extend .round-counter;
}

.fault-type-name {
  @extend .modal-prominent-text;
}

.no-comments {
  color: $gray;
  font-size: 14px;
}

.same-issues-history-button-container:active {
  transform: translateY(3px);
}

.same-issues-history-button-container {
  width: 54px;
  height: 54px;
  border-radius: 27px;
  background-color: $brand-blue;
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 10 !important;
  border: none;
  box-shadow: 0 0 20px 10px $brand-blue-transparent;
  animation: pulsate 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  outline: none;

  .same-issues-history-button {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: $brand-blue;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .counter {
    @extend .round-counter;
    z-index: map-get($zindex, overlay);
    right: 0px;
    bottom: 40px;
    box-shadow: 0 2px 4px 0 $transparent-black;
  }

  @keyframes pulsate {
    0% {
      box-shadow: 0 0 0 $brand-blue-transparent;
    }
    50% {
      box-shadow: 0 0 20px 10px $brand-blue-transparent;
    }
    100% {
      box-shadow: 0 0 0 $brand-blue-transparent;
    }
  }
}

.select-previous-test-case {
  height: fit-content !important;
}

.same-issues-content-container {
  height: 100%;
}

.subtitle {
  color: #7f7f7f;
  font-size: 1.5rem;
}

.comments-container {
  padding: 20px;
}
