#virtual-vehicle-configuration-sales-code-loading {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  background-color: rgba(39, 39, 39, 0.2);
  border-radius: 5px;
}

.vehicle-configuration-collapse-search {
  width: 300px;
}

.vehicle-configuration-breadcrumb-wrapper {
  display: inline-block;
  padding-left: 5px;
}

.virtual-vehicle-configuration-sales-code-select-form-container {
  height: 100%;
  overflow-y: scroll;
  position: relative;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  color: black;
}
