#production-line-page {
  width: 100%;
  height: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  .page-name {
    height: 7%;
    text-align: center;
  }

  #production-line-results {
    .ant-table-wrapper {
      width: 70%;
    }
  }

  .production-line-form {
    height: 20%;

    .production-line-test-zone,
    .production-line-plant,
    .production-line-time {
      height: fit-content;
    }

    .production-line-plant {
      width: 10%;
    }

    .production-line-test-zone {
      width: 40%;
    }

    .production-line-time {
      width: 15%;
    }
  }
}
