.overlay-navigation-container {
  position: absolute;
  top: 95%;
  z-index: 2 !important;
  height: fit-content !important;
  width: 50%;
  pointer-events: none;

  .ant-radio-group {
    pointer-events: all;
  }
}

.add-fault-button {
  position: absolute;
  top: 95%;
  z-index: 2 !important;
}
