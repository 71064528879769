.functional-diagrams-error-boundary-result {
  width: 100%;
  height: 100%;
}

.functional-diagrams-root-container {
  height: 100%;

  .functional-diagrams-left-panel {
    width: 50%;
    height: 100%;
    transition-duration: 300ms;
    transition-property: width;

    .functional-diagrams-left-panel-header {
      position: relative;
    }

    &.expand {
      width: 100%;
    }

    .functional-diagrams-left-panel-content {
      height: 100%;

      .functional-diagrams-left-panel-content-diagram {
        flex-grow: 1;
        background-color: var(--color-background-main);
        .block-diagram {
          height: 99% !important;
        }
      }
    }

    .functional-diagrams-expand-button {
      z-index: 1;
      position: absolute;
      right: 10px;
      top: 43px;
    }
  }

  .functional-diagrams-right-panel {
    width: 50%;
    height: 100%;
    border-left: 1px solid var(--color-border-custom);
    position: relative;
    transition-duration: 300ms;
    transition-property: width;

    &.collapse {
      display: none;
    }

    .functional-diagrams-right-controls-header {
      padding: 10px;
    }

    .functional-diagrams-right-panel-content {
      height: 97%;
    }

    .functional-diagram-table-wrapper {
      padding: 10px;
    }
  }
}

.functional-diagrams-filter-selection-info {
  width: 65px;
  font-size: smaller;
}

.functional-diagrams-breadcrumb {
  border-bottom: 1px solid var(--color-border-custom);
  padding: 5px 10px;

  .functional-diagrams-breadcrumb-wrapper {
    display: inline-block;
  }

  .back-button {
    margin-right: 10px;
    cursor: pointer;
  }

  .button-disabled {
    cursor: not-allowed !important;
  }
}

.functional-diagrams-info-panel {
  height: 50%;
  z-index: 1;
  width: 100%;
  border-top: 1px solid var(--color-border-custom);

  .functional-diagrams-tabs {
    padding-top: 10px;
  }

  .functional-diagrams-connector-pinout {
    padding: 5px;
  }
}

.functional-diagrams-info-panel-legend {
  padding-left: 3%;
  border-bottom: 1px solid var(--color-border-custom);
  height: 15%;
}

.functional-diagrams-loading-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.block-diagram-circuit-category-icon {
  svg {
    height: 20px;
    width: 20px;

    g path {
      fill: var(--color-fill-pinout-table-circuit);
    }
  }
}

.functional-diagrams-empty {
  height: 100%;
}

.functional-diagrams-detail-info-panel {
  border-top: 1px solid var(--color-border-custom);
  height: 55%;
  position: relative;
  overflow: hidden;
}

.functional-diagrams-detail-info {
  height: 100%;
}

.detail-diagrams-circuit-info-component-images {
  height: 85%;
  width: 100%;
}

.detail-diagrams-component-info {
  height: 85%;
  width: 100%;
}
.detail-diagrams-circuit-info-from-component {
  width: 50%;
}

.component-neighbors-table-container {
  height: 100%;
  padding: 5px;
}

.detail-diagrams-circuit-info-to-component,
.detail-diagrams-component-info-table {
  width: 50%;
}

.functional-diagrams-detail-pin-info-table {
  width: 50%;
  padding: 5px;
  &.expand {
    width: 70%;
  }
}

// check here
.functional-diagrams-detail-pin-info-image-divider {
  z-index: 1;
  position: absolute;
  right: 20px;
  top: 20px;
}

.functional-diagrams-detail-pin-info-image-panel {
  position: relative;
  width: 50%;
  border-left: 1px solid var(--color-border-custom);
  &.minimize {
    width: 30%;
  }
}

.functional-diagrams-detail-pin-info-image {
  width: 100%;
}

.detail-diagrams-circuit-info-to-component {
  border-left: 1px solid var(--color-border-custom);
}

.detail-diagrams-component-info-image {
  width: 50%;
  border-left: 1px solid var(--color-border-custom);
}

.functional-diagrams-loading {
  display: 'flex';
  height: '100%';
  align-items: 'center';
  justify-content: 'center';
}

.width-50 {
  width: 50%;
}

.height-50 {
  height: 50%;
}

.functional-diagrams-block-diagram-image {
  height: 50%;
}

.functional-diagrams-detail-pin-info {
  display: flex;
  height: 100%;
  width: 100%;
}

.functional-diagrams-component-or-location-view {
  height: 100%;
}

.functional-diagrams-component-or-location-view-image {
  height: 85%;
}

.functional-diagrams-component-or-location-view-ctrls {
  height: 15%;
  padding: 0px 20px;
  border-top: 1px solid var(--color-border-custom);
}

.circuit-color-icon {
  height: 20px;
  width: 20px;
}

.functional-diagrams-sales-codes {
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-left: 50px;
}

.functional-diagrams-sales-codes-input {
  padding-top: 24px;
}

.functional-diagrams-sales-codes-submit {
  margin-top: 24px;
  width: 10%;
}

.functional-diagrams-make-model-select {
  width: 30%;
}

.functional-diagrams-info-panel .ant-tabs-content,
.functional-diagrams-info-panel .ant-tabs-tabpane {
  height: 100%;
}

.fuse-details-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .location-details-container,
  .fuse-color-image-container {
    height: 100%;
    width: 50% !important;
  }

  .location-details-container {
    border-left: 1px solid var(--color-border-custom);
  }
}

.fuse-color-image-container {
  background-color: var(--color-background-main);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.fuse-color-image-container .connector-preview-box {
  opacity: 1;
  position: static;
}

.fuse-color-image-container .svg-viewer {
  transform: scale(2);
  padding-left: 10%;
}

.detail-diagram-zoom-control {
  top: 87% !important;
}

.functional-diagrams-search {
  margin-left: 5px;
}
