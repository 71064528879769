.detail-label {
  pointer-events: none;
  font-size: 12px;
  text-rendering: geometricPrecision;
  user-select: none;
  stroke: var(--color-diagram-text-stroke);
  stroke-width: 0.5em;
  fill: var(--color-diagram-text-fill);
  paint-order: stroke;
  stroke-linejoin: round;
}
