.no-graph-container {
  position: relative;
}

#no-graph-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-main);
}

.no-graph-error-message {
  text-decoration: none;
  font-size: 1.8rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  text-align: center;
  p {
    font-size: 18px;
    opacity: 0.9;
    margin: 20px 100px 20px 100px;
  }
  h2 {
    margin-bottom: 20px;
  }
  svg {
    width: 100%;
  }
  .feedback {
    color: #0189e9;
    cursor: pointer;
  }
}
