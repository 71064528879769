.qr-video-container {
  position: relative;
  width: 75%;
  height: 75%;
  border: 4px dashed gray;
}

.qr-video-container .qr-video {
  width: 100%;
  height: 100%;
}

.border-detected {
  border-color: green;
}

.border-not-detected {
  border-color: gray;
}

.status-valid {
  color: green;
}

.status-invalid {
  color: red;
}
