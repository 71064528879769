.pin-details-left-pane-connector-details {
  flex-basis: 10%;
}

.pin-details-left-pane-connector-container {
  flex-basis: 90%;
  overflow-y: scroll;
}

.pin-details-table-container {
  height: 40%;
}

.pin-details-left-pane {
  height: 100%;
  padding: 10px;
  padding-top: 15px;
  border-right: 1px solid var(--color-border-custom);
}

.pinout-details-component {
  padding-top: 15px;
}

.connector-preview-container {
  height: 60%;
  padding-top: 20px;

  .connector-preview-button {
    width: 50%;
  }
}

.connector-preview-container > :first-child {
  width: 70%;
  height: 70%;
}
