.vehicle-test-container {
  height: 100%;
}

.vehicle-test-left-side-panel-container {
  flex-basis: 16.2%;
  border-right: 1px solid var(--color-border-custom);
  height: 100%;
}

.vehicle-test-left-side-panel-container > div {
  height: 100%;
}

.left-side-panel-container > * {
  height: 100%;
}

.vehicle-test-center-container {
  flex-basis: 83.8%;
  position: relative;
}

.vehicle-test-view-container {
  flex-basis: 100%;
}

.collapsible-side-dtc-side-panel {
  flex-basis: 18%;
  border: 1px solid var(--color-border-custom);
}

.vehicle-test-collapsible-right-panel-container {
  z-index: 4;
  width: 308px;
  position: absolute;
  height: 100%;
  right: 0;
  pointer-events: none;
}

#header-component-details {
  border-bottom: 1px solid var(--color-border-custom);
  height: 26px;

  h5 {
    margin: 7.2px 20px;
  }
}
