#vehicle-tests-search {
  height: 100%;
  left: 0px;
  margin: auto;
  position: absolute;
  right: 0;
  width: 100%;
  padding: 0 18%;

  .action-button {
    margin-right: 3px;
  }
}

#vehicle-tests-search h2 {
  font-size: 22px;
  font-weight: 400;
}

#vehicle-tests-search label {
  font-weight: normal;
}

#vehicle-test-help-message {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 10px;
  text-align: center;
}

.vehicle-search-form {
  width: 100%;
  gap: 25px;

  .search-input {
    flex-basis: 75%;
  }
}
