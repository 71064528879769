#digraph {
  // Need to keep the graph at it's max size so that cytoscape renders it correclty
  height: 100%;
  width: 100%;
}

#app-layout {
  height: 100%;
  width: 100%;
  min-width: 500px;
  margin: 0;
}
