#global-search-page {
  height: 100%;

  .global-search-container {
    width: 100%;
    height: 93%;

    .global-search-left-panel {
      border-right: 1px solid var(--color-border-custom);
      width: 50%;

      .left-panel-body {
        height: 96.5%;
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px;
        .empty {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
        }

        .component-location-or-pinout {
          height: 100%;

          .component-location-or-pinout-toggle {
            height: 5%;
          }

          .component-location-or-pinout-view {
            height: 90%;

            .global-search-component-overlay {
              position: absolute;
              top: 40%;
              left: 40%;
            }
          }

          .global-search-related-subsystem-button {
            display: inline-block;
          }
        }
      }

      .left-panel-body-dtc {
        height: 96.5%;
        padding: 15px;
      }
    }

    .global-search-right-panel {
      width: 50%;

      .right-panel-body {
        padding: 15px;
        height: 96.5%;
        .switch-radio-option {
          align-items: center;
          .ant-badge {
            height: fit-content;
          }
        }
      }
    }

    .panel-header {
      padding: 5px 20px;
      height: 3.5%;
      border-bottom: 1px solid var(--color-border-custom);
    }

    .global-search-results-table {
      height: 100%;
    }
  }

  .global-search-bottom-panel {
    min-height: 7%;
    border-top: 1px solid var(--color-border-custom);
    padding: 15px 0;
    padding-left: 15px;
    button {
      margin: 0 10px;
    }
  }
}
