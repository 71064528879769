.vehicle-configuration-container {
  height: 89%;
  border-bottom: 1px solid var(--color-border-custom);
  .vehicle-configuration-right-panel {
    border-left: 1px solid var(--color-border-custom);
  }
}

.vehicle-configuration-footer {
  height: 7%;
  background-color: var(--color-footer-custom);
  padding: 15px;
}

.vehicle-configuration-left-panel {
  padding: 20px;
}

.vehicle-configuration-right-panel {
  padding: 20px;

  .no-selection {
    height: 100%;
  }
}

.vehicle-configuration-breadcrumb {
  padding: 5px;
  padding-left: 10px;
  border-bottom: 1px solid var(--color-border-custom);
}
