.edge {
  &.disabled {
    pointer-events: none;
  }

  &:not(.selectionDisabled):not(.disabled) {
    &:hover {
      .path {
        stroke: var(--color-diagram-edge-hover);
      }
    }

    .path {
      &.active {
        stroke: #0089e9;
      }
    }

    .clicker {
      cursor: pointer;
    }
  }
}

.path {
  fill: transparent;
  pointer-events: none;
  shape-rendering: geometricPrecision;
}

.clicker {
  fill: none;
  stroke: transparent;
  stroke-width: 15px;

  &:focus {
    outline: none;
  }
}
