.title-container {
  vertical-align: center;
  font-weight: 500;
  font-size: 2rem;
}

.header-fault-type-selection {
  margin: 0px auto;
  width: fit-content;
}

.panel {
  border-radius: 3px;
  background-color: white;

  .row {
    margin: 10px 15px;
  }

  label {
    color: black;
  }

  .generic-form-button-container {
    margin: 20px 0px;
    margin-bottom: 0px;
    padding: 10px 5px;
    padding-bottom: 0px;
    border-top: 1px solid #d9d9d9;

    button {
      margin: 0px 10px;
    }
  }

  .panel-body {
    padding: 15px 0px;
  }
}

.component-harness-fault-rightPane {
  padding: 15px;
  border-left: 1px solid var(--color-border-custom);
}

.component-harness-fault-leftPane {
  .image-viewer {
    background-color: var(--color-background-alternate);
    height: 100%;
  }
  .image-viewer-no-image {
    background-color: var(--color-background-main);
    height: 100%;
  }
}

.device-fault-rightPane {
  padding: 15px;

  .ant-flex {
    height: 100%;
  }
}

.component-harness-table-container {
  padding-top: 25px;
  border-radius: 5px;
}

.component-table,
.harness-table,
.device-table {
  width: 100%;
}

.component-harness-fault-rightPane-top {
  flex-basis: 50%;
}

.component-harness-fault-rightPane-bottom {
  flex-basis: 50%;
}

.device-header {
  padding-bottom: 5px;
}

.repair-history-form-wrapper {
  background-color: var(--color-form-container);
  height: fit-content !important;
  padding: 20px;
  border-radius: 5px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.generic-form-wrapper {
  max-width: 40%;
}

.device-fault-wrapper {
  height: 100%;
  width: 100%;
}

.device-fault-wrapper-row {
  height: 100%;
  width: 45%;
}

.device-table {
  .ant-table {
    border-radius: 0px;
  }
}
