#pin-table {
  // Override the positioning so the gradient does not overlay on the table
  height: 100%;
  position: relative;
  background-color: var(--color-background-main);
}

.circuit-category-img {
  width: 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  g path {
    fill: var(--color-fill-pinout-table-circuit);
  }
}
