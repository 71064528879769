.mapping-table-wrapper {
  border-radius: 5px;
  flex-basis: 45%;

  .mapping-table-header {
    padding: 0px 5px 5px 0px;
    border-radius: 5px 5px 0px 0px;
  }

  .ant-table {
    border-radius: 0px;
  }
}

.start-component-header {
  border-bottom: 1px solid var(--color-border-custom);
  .wizard-nav-breadcrumb {
    margin: 0px;
    font-size: 1.5rem;
  }
}

.start-component-content-container {
  height: calc(100% - 64px);
}

.start-component-content {
  height: 100%;
}

.start-component-content-left-pane-view-selector {
  border-bottom: 1px solid var(--color-border-custom);
  flex-basis: 5%;
  padding: 10px;
}

.start-component-content-left-pane-view {
  flex-basis: 95%;
}

.start-component-content-left-pane {
  height: 100%;
  border-right: 1px solid var(--color-border-custom);
}

.topology-container {
  height: 100%;
}

.start-component-content-left-pane > .ant-flex {
  height: 100%;
}

.add-connector-mapping-container {
  height: 100%;
}

.add-connector-mapping-header {
  border-bottom: 1px solid var(--color-border-custom);
  .wizard-nav-breadcrumb {
    margin: 0px;
  }
}

.add-connector-mapping-content {
  height: calc(100% - 64px);

  .add-connector-mapping-content-right-pane {
    border-left: 1px solid var(--color-border-custom);
    padding: 10px;
  }
}

.add-connector-mapping-content-right-pane > .ant-flex {
  height: 100%;
}

.add-connector-mapping-content > .ant-row {
  height: 100%;
}

.add-connector-mapping-content-right-pane-search {
  flex-basis: 5%;
}

.add-connector-mapping-content-right-pane-table-container {
  flex-basis: 95%;
}

.dtc-memo-content {
  background: var(--color-form-container);
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  height: 40%;
}

.dtc-mapping-content {
  background: var(--color-form-container);
  width: 50%;
  border-radius: 5px;
  padding: 7px;
  padding-top: 0px;
}

.dtc-mapping-table-container {
  padding: 5px;
}

.dtc-details {
  padding-bottom: 10px;
}

.dtc-description {
  padding: 10px 0px;
}

.text-editor-container {
  padding: 10px 0px;
}

.form-button-container {
  padding: 10px 0px;
  button {
    margin-right: 10px;
  }
}

.title {
  padding: 15px;
}

.dtc-memo-mapping-container {
  height: 100%;
}

.dtc-memo-mapping-content {
  height: 100%;
}

.manage-mapping-title {
  padding: 10px;
}

#quill-description {
  .ql-toolbar {
    padding-left: 0px;
    padding-top: 0px;
    border: 0px;
    .ql-thin {
      stroke: none;
    }
    .ql-stroke {
      stroke: var(--color-fill-pinout-table-circuit);
    }
    .ql-fill {
      fill: var(--color-fill-pinout-table-circuit);
    }
  }

  .ql-container {
    border-top: 1px solid #ccc;
    border-radius: 5px;
    height: 90px;
    background-color: var(--color-text-editor-container);
  }
}
