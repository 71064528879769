.dtc-popover-content {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 225px;
    height: 33px;
    border-radius: 5px;
    background-color: #ffc700;
  }

  .block {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .block-label {
      display: flex;
      justify-content: flex-start;
      height: 16px;
      font-size: 13px;
      font-weight: 500;
      color: #7f7f7f;
    }

    .block-text {
      display: flex;
      justify-content: flex-start;
      height: 18px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
