.vin-panel-container {
  border-radius: 5px;
  margin: 5px;

  .vin-panel {
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: normal;

    .vin-item,
    .current-test-info {
      padding: 10px 0 2px;
    }

    .vin-panel-left {
      float: left;
      padding-left: 10px;
      width: 90%;
    }

    .vin-panel-right {
      display: flex;
      flex-direction: column;
      float: right;
      padding: 13px 10px 10px 0;
      width: 10%;
    }
  }

  .cantfix {
    background-color: #cc422e;

    span:nth-child(2) {
      color: #ffca57 !important;
    }
  }
}

//embedded
/* Get smaller when Bootstrap changes panel width */
@media all and (min-width: $screen-sm-min) and (max-width: 890px),
  all and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  .vin-panel {
    .vin {
      font-size: $small-font-size;
    }
  }
}
