.root-header-container {
  padding: 0px;

  div {
    height: 100%;
  }

  border-bottom: 1px solid var(--color-border-custom);

  .header-image-container {
    padding-right: 0px !important;

    .navbar-logo {
      display: block;
      background-color: $brand-blue;
      padding: 0px;
      height: 100%;
      .logo-container {
        flex-wrap: nowrap;
        height: 100%;
        .logo-image-container {
          width: 50%;
          padding: 2px;
          padding-top: 0px;
          img {
            margin: 0;
            width: 100%;
          }
          &:nth-child(2) {
            background-color: #0078cc;
          }
        }
      }
    }
  }

  .root-header-view-selector {
    text-align: center;
  }

  .header-user-profile-container {
    display: flex;
    justify-content: center;
    gap: 7%;
    align-items: center;

    .header-theme-selector {
      flex-basis: 10%;
    }
  }
}

.vehicle-name-description {
  height: 50%;
  margin: 0px;
  display: flex;
  align-items: center;
}

// User menu and vehicle make model detail dropdowns
.user-menu,
.vehicle-make-model-menu {
  .collapsible {
    min-width: 100px;
    display: flex;
    align-items: center;
    gap: 5%;

    .vehicle-name,
    .username {
      flex-basis: 65%;
      white-space: nowrap;
    }

    button {
      height: 50%;
    }
  }
}

.user-menu {
  flex-basis: 80%;
}

.make-model-label {
  color: #90939bff;
}

#user-menu-popover {
  width: 300px;
  z-index: map-get($zindex, overlay) !important;

  .popover-content {
    padding: 0px;
  }

  .content-row {
    padding: 15px 30px;
    font-weight: 300;
  }

  .username {
    padding: 24px 30px;
    font-weight: $bold;
    word-wrap: break-word;
  }

  .separator {
    height: 2px;
    margin: 0;
    background-color: $light-gray;
  }

  .tracer-version {
    color: $gray;
    font-size: 12px;
    text-align: center;
  }
}

#change-start-view-form {
  input[name='changeStartView'] {
    margin: 2px 0 0 -20px;
  }
}

#vehicle-details-container {
  max-height: 350px;
  overflow-y: auto;
}
